<template>
	<div class="box">
		<div class="mi-orderlist">
			<div class="container ">
				<div class="row" style="display: flex;">
					<div class="span4" ref="J_siteUserMenu">
						<div class="user-address-menu" id="J_userMenu">
							<div class="menu-box" v-for="(item,index) in bangzhulist" :key="index">
								<h3 class="title" v-if="item.Contents">
								<router-link  :to="{ path: '/helpinfo', query: { id: item.Id }}" @click.native="flushCom">
									{{ item.Title }}
								</router-link></h3>
								<h3 class="title" v-else>
									{{item.Title}}
								</h3>
								<ul class="list">
									<li v-for="(itclass,classindex) in item.ChildrenList" :key="classindex"
										:class="[routerIndex==itclass.Id?'menuActive':'']">
										<router-link  :to="{ path: '/helpinfo', query: { id: itclass.Id }}" @click.native="flushCom">
											{{ itclass.Title }}
										</router-link>
									</li>
								</ul>
							</div>
						</div>

					</div>
					<div class="span16">
						<router-view></router-view>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				routerIndex: null,
				bangzhulist:[],
			};
		},
		created() {
			this.routerIndex = this.$route.query.id
			this.loadbangzhuList()
			
		},
		methods: {
			flushCom() {
				this.$router.go(0)
			},
			async loadbangzhuList() {
				var res = await this.postdata("/api/News/HelpCenterList", {
					page: 1,
					limit: 20,
					"parentId": 0 
				});
				if (res.code == 200) {
					this.bangzhulist = res.data.items;
				}
			},
		}
	};
</script>
<style scoped>
	.menuActive a {
		color: #603c1d !important;
	}

	.uc-main-box {
		padding: 36px 0;
	}

	.mi-orderlist {
		background: #f5f5f5;
		padding-bottom: 40px;
	}

	.container {
		width: 1226px;
		margin-right: auto;
		margin-left: auto;
	}

	.container:after,
	.container:before {
		content: " ";
		display: table;
	}

	.row {
		margin-left: -14px;
	}

	.row:after,
	.row:before {
		content: " ";
		display: table;
	}

	.span4 {
		width: 234px;
		flex-shrink: 0;
	}

	.user-address-menu {
		background: #fff;
		padding: 36px 0;
	}

	.user-address-menu .menu-box {
		margin: 0 48px 12px;
	}

	.user-address-menu h3 {
		font-size: 16px;
		font-weight: 400;
		line-height: 52px;
		color: #333;
		margin: 0;
		padding: 0;
	}

	.user-address-menu ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.user-address-menu li {
		padding: 6px 0;
		font-size: 14px;
	}

	.user-address-menu .active a,
	.user-address-menu .active a:hover {
		color: #ff6700;
	}

	.user-address-menu li a {
		color: #757575;
	}

	.span16 {
		margin-left: 16px;
		width: 100%;
	}
</style>
